import request from '../request.js'
export function projectUserAdminGetListOrder(data){
    return request({  //利用我们封装好的request发送请求
        url: '/project/user/admin/get/list/order',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data: data,//向后端传递数据
    })
}
export function deleteProjectOrder(data){
    return request({  //利用我们封装好的request发送请求
        url: '/project/delete/project/order',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data: data,//向后端传递数据
    })
}