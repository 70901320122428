<template>
  <div>
    <el-form :inline="true" :model="searchInfo" class="demo-form-inline">
      <el-form-item style="margin-left: 30px" label="订单状态">
        <el-select
            v-model="searchInfo.orderState"
            clearable
            placeholder="请选择订单状态"
        >
          <el-option
              key="1"
              label="已完成"
              value="1"
          >
          </el-option>
          <el-option
              key="2"
              label="未支付"
              value="2"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="margin-left: 30px" label="项目类型">

        <el-select
            v-model="searchInfo.projectId"
            clearable
            placeholder="请选择项目"
        >
          <el-option
              key="1"
              label="vip订单"
              value="1"
          >
          </el-option>
          <el-option
              key="2"
              label="五行命理测算订单"
              value="2"
          >
          </el-option>
          <el-option
              key="3"
              label="每一选衣订单"
              value="3"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="margin-left: 30px" label="用户名称">
        <el-input
            v-model="searchInfo.userName"
        ></el-input>
      </el-form-item>
      <el-form-item style="margin-left: 30px" label="订单编号">
        <el-input
            v-model="searchInfo.orderNumber"
        ></el-input>
      </el-form-item>
      <el-form-item class="right-btns">
        <el-button type="primary" @click="getList">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="tableData"
        border
        style="width: 100%">
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>

      <el-table-column
          label="订单编号"
          prop="orderNumber"
          width="120"
          >
      </el-table-column>
      <el-table-column
          label="用户名称"
          prop="userName"
          width="80"
      >
      </el-table-column>
      <el-table-column
          label="项目价格"
          prop="orderPrice"
          width="80"
      >
      </el-table-column>
      <el-table-column
          label="其他费用"
          prop="carPrice"
          width="80"
      >
      </el-table-column>
      <el-table-column
          label="订单总价"
          prop="totalPrice"
          width="80"
      >
      </el-table-column>
      <el-table-column
          label="实际付费"
          prop="realityPrice"
          width="80"
      >
      </el-table-column>
      <el-table-column
          label="订单状态"
          align="center" width="80"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.orderState === 1">完成</span>
          <span v-if="scope.row.orderState === 2">未支付</span>
        </template>
      </el-table-column>
      <el-table-column
          label="支付方式"
          align="center" min-width="80"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.paymentType === 1">微信支付</span>
          <span v-if="scope.row.paymentType === 2">支付宝支付</span>
        </template>
      </el-table-column>
      <el-table-column
          label="创建时间"
          prop="createDate"
          width="200"
      >
      </el-table-column>
      <el-table-column
          label="信息最近修改时间"
          prop="updateDate"
          width="200"
      >
      </el-table-column>
      <el-table-column
          label="项目名称"
          prop="projectName"
          width="100"
      >
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
<!--          <el-button-->
<!--              size="mini"-->
<!--              @click="handleEdit(scope.$index, scope.row)">编辑</el-button>-->
          <el-button
              size="mini"
              type="danger"
              @click="deleteProjectOrder( scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-bottom: 20px">
      <el-col :span="24">
        <div style="padding-top: 20px;">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[5, 10, 50, 100]"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {projectUserAdminGetListOrder,deleteProjectOrder} from '../../api/projectOrder/index';
export default {
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pagesize: 10,
      total: 0,
      searchInfo:{
        orderState:null,
        userName:null,
        orderNumber:null,
        projectId:null
      }
    }
  },
  methods: {
    getList(){
      // this.$router.replace('/projectList')
      let that = this;
      // let param = new URLSearchParams();
      // param.append('pageSize', this.pagesize);
      // param.append('pageNumber', this.currentPage);
      const pageParameter = {
        "pageIndex":this.currentPage,
        "pageSize": this.pagesize,
        "parameter": {"type":1,
          "orderState":this.searchInfo.orderState
        ,"userName":this.searchInfo.userName
        ,"orderNumber":this.searchInfo.orderNumber
        ,"projectId":this.searchInfo.projectId
        }
      }
      const token = {
        "token":JSON.parse(localStorage.getItem("userInfo")).token
      }
      console.log(token)
      projectUserAdminGetListOrder(pageParameter,token).then(res =>{
        console.log(res);
        let len = res.data.body.list.length;
        that.total = res.data.body.count;
        that.tableData = [];
        for(let i = 0; i<len; i++){
          that.tableData.push(res.data.body.list[i]);
        }
      })

    },
    handleSizeChange: function (size) {
      this.pagesize = size;
      this.getList();
      console.log(this.pagesize)  //每页下拉显示数据
    },
    handleCurrentChange: function(currentPage){
      this.currentPage = currentPage;
      console.log(this.currentPage)  //点击第几页
      this.getList();
    },
    deleteProjectOrder(projectOrderRecord){
      this.$confirm("是否确认删除此订单记录","提示",{
        iconClass: "el-icon-question",//自定义图标样式
        confirmButtonText: "确认",//确认按钮文字更换
        cancelButtonText: "取消",//取消按钮文字更换
        showClose: true,//是否显示右上角关闭按钮
        type: "warning",//提示类型  success/info/warning/error
      }).then(()=>{
        //确认操作
        deleteProjectOrder(projectOrderRecord).then(res =>{
          if (res.data.code===1001){
            this.msgsuccess('删除成功');
            this.getList();
          }else {
            this.msgerror(res.data.msg);
          }
        })
      }).then((data) => {
        console.log(data);
        //取消操作
      }).catch((err) => {
        //捕获异常
        console.log(err);
      });
    }
  },
  mounted(){
    this.getList();
  }
}

</script>